<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">
      <div class="editor form-control mt-1 w-100">
        <editor-floating-menu :editor="editor" v-slot="{ commands, isActive, menu }">
          <div
            class="editor__floating-menu"
            :class="{ 'is-active': menu.isActive }"
            :style="`top: ${menu.top}px`"
          >

            <div
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({ level: 1 }) }"
              @click="commands.heading({ level: 1 })"
            >
              <span class="text">H<sup>1</sup></span>
            </div>

            <div
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({ level: 2 }) }"
              @click="commands.heading({ level: 2 })"
            >
              <span class="text">H<sup>2</sup></span>
            </div>

            <div
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({ level: 3 }) }"
              @click="commands.heading({ level: 3 })"
            >
              <span class="text">H<sup>3</sup></span>
            </div>

            <div
              class="menubar__button"
              :class="{ 'is-active': isActive.bullet_list() }"
              @click="commands.bullet_list"
            >
              <i class="icon-list-unordered"></i>
            </div>

            <div
              class="menubar__button"
              :class="{ 'is-active': isActive.ordered_list() }"
              @click="commands.ordered_list"
            >
              <i class="icon-list-ordered"></i>
            </div>

            <div
              class="menubar__button"
              :class="{ 'is-active': isActive.blockquote() }"
              @click="commands.blockquote"
            >
              <i class="icon-quotes-left"></i>
            </div>

          </div>
        </editor-floating-menu>

        <editor-menu-bubble :editor="editor" :keep-in-bounds="true" v-slot="{ commands, isActive, getMarkAttrs,   menu }" @hide="hideLinkMenu">
          <div
            class="menububble"
            :class="{ 'is-active': menu.isActive }"
            :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
          >


            <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
              <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
              <div class="menububble__button" @click="setLinkUrl(commands.link, null)">
                <i class="icon-cross3"></i>
              </div>
            </form>

            <template v-else>
              <div
                class="menububble__button"
                :class="{ 'is-active': isActive.bold() }"
                @click="commands.bold"
              >
                <i class="icon-bold2"></i>
              </div>

              <div
                class="menububble__button"
                :class="{ 'is-active': isActive.italic() }"
                @click="commands.italic"
              >
                <i class="icon-italic2"></i>
              </div>
              <div
                class="menububble__button"
                @click="showLinkMenu(getMarkAttrs('link'))"
                :class="{ 'is-active': isActive.link() }"
              >
                <i class="icon-link"></i>
              </div>

              <div
                v-if="!isActive.bullet_list() && !isActive.ordered_list()"
                class="menububble__button"
                :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                @click="commands.heading({ level: 1 })"
              >
                <span class="text">H<sup>1</sup></span>
              </div>

              <div
                v-if="!isActive.bullet_list() && !isActive.ordered_list()"
                class="menububble__button"
                :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                @click="commands.heading({ level: 2 })"
              >
                <span class="text">H<sup>2</sup></span>
              </div>

              <div
                v-if="!isActive.bullet_list() && !isActive.ordered_list()"
                class="menububble__button"
                :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                @click="commands.heading({ level: 3 })"
              >
                <span class="text">H<sup>3</sup></span>
              </div>

              <div
                v-if="!isActive.heading({ level: 1 }) && !isActive.heading({ level: 2 }) && !isActive.heading({ level: 3 })"
                class="menububble__button"
                :class="{ 'is-active': isActive.bullet_list() }"
                @click="commands.bullet_list"
              >
                <i class="icon-list-unordered"></i>
              </div>

              <div
                v-if="!isActive.heading({ level: 1 }) && !isActive.heading({ level: 2 }) && !isActive.heading({ level: 3 })"
                class="menububble__button"
                :class="{ 'is-active': isActive.ordered_list() }"
                @click="commands.ordered_list"
              >
                <i class="icon-list-ordered"></i>
              </div>

              <div
                class="menububble__button"
                :class="{ 'is-active': isActive.blockquote() }"
                @click="commands.blockquote"
              >
                <i class="icon-quotes-left"></i>
              </div>
            </template>


          </div>
        </editor-menu-bubble>

        <editor-content class="editor__content" :editor="editor"/>
      </div>

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">

      <div v-html="nl2br(value)" />

    </template>

  </div>

</template>

<script>


import {
  Editor,
  EditorContent,
  EditorFloatingMenu,
  EditorMenuBubble,
} from 'tiptap'
import {
  Blockquote,
  BulletList,
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Underline,
  History,
  TrailingNode,
  Placeholder,
} from 'tiptap-extensions'

import Field from './_Field'

export default {
  extends: Field,
  components: {
    EditorContent,
    EditorFloatingMenu,
    EditorMenuBubble,
  },
  data () {
    return {
      editor: null,
      linkMenuIsActive: false,
      linkUrl: null,
    }
  },
  beforeDestroy () {
    this.$nextTick(() => {
      this.unloadEditor()
    })
  },
  mounted () {
    this.loadEditor()
  },
  methods: {
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    },
    hideLinkMenu() {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl(command, url) {
      command({ href: url })
      this.hideLinkMenu()
    },
    changeVal(val) {
      this.$emit('input', val)
    },
    refreshEditor () {
      this.unloadEditor()
      this.loadEditor()
    },
    unloadEditor () {
      if (this.editor && this.editor != null) {
        this.editor.destroy()
      }
    },
    loadEditor () {
      this.editor = new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Italic(),
          new History(),
          new TrailingNode({
            node: 'paragraph',
            notAfter: ['paragraph'],
          }),
          new Underline(),
          new Placeholder({
            emptyNodeClass: 'is-empty',
            emptyNodeText: this.placeholder,
            showOnlyWhenEditable: true,
          }),
        ],
        content: this.value,
        onBlur: () => {
          this.changeVal(this.editor.getHTML().trim())
        },
        onFocus: () => {
        }
      })
    }
  },
  computed: {
  },
  watch: {
    value: {
      initial: true,
      handler (newVal) {
        this.refreshEditor()
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.editor.form-control {
  min-height: 72px;
}
/deep/ .editor__content {
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    font-weight: 500;
    border-bottom: 2px #ccc dashed;
    cursor: pointer;
  }
}
</style>
