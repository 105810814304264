<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">

      <input-tag
        :required="required"
        v-model="clonedValue"
        class="form-control"
        :placeholder="placeholder.title"
        @input="changeVal(clonedValue)"
      />

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">

      <template v-if="config.reference">
        <span class="hashtag mr-1 mb-1" v-for="elem in value">#{{ config['options'][elem] }}</span>
      </template>
      <template v-else>
        <span class="hashtag mr-1 mb-1" v-for="elem in value">#{{ $te(`modules.${$route.params.module}.entities.${$route.params.entity}.fields.${id}.${elem}`) ? $t(`modules.${$route.params.module}.entities.${$route.params.entity}.fields.${id}.${elem}`) : elem }}</span>
      </template>

    </template>

  </div>
</template>

<script>

import Field from './_Field'

export default {
  extends: Field,
  components: {
  },
  data () {
    return {
      clonedValue: [],
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val)
    },
  },
  computed: {
  },
  watch: {
    value (newVal) {
      if (newVal) {
        this.clonedValue = newVal
      } else {
        this.clonedValue = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
