<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">

      <select
        class="form-control"
        :required="required"
        @change="changeVal"
      >
        <option value=""></option>
        <option :selected="optionKey == value" v-for="(option, optionKey) in config.options" :id="option" :value="optionKey" type="radio">
          <template v-if="config.reference">
            {{ option }}
          </template>
          <template v-else>
            {{ $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}.options.${option}`) }}
          </template>
        </option>
      </select>

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">

      <template v-if="config.reference">
        {{ value }}
      </template>
      <template v-else>
        {{
          $te(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}.options`) ?
            $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}.options.${value}`) : $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}.${value}`)
        }}
      </template>

    </template>

  </div>
</template>

<script>

import Field from './_Field'

export default {
  extends: Field,
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val.target.value)
    },
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>
