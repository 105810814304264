<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">

      <div class="position-relative">
        <input :required="required" :value="value" type="text" class="form-control w-100" @change="changeVal" placeholder="#FFFFFF" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" />
        <div class="color-thumb rounded border" v-if="value" :style="'background:' + value"></div>
      </div>

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">

      <span class="d-inline-block border rounded"><code :style="`border-left: 40px solid ${value}`" class="px-3 d-inline-block text-dark">{{ value }}</code></span>

    </template>

  </div>
</template>

<script>

import Field from './_Field'

export default {
  extends: Field,
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val.target.value)
    },
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
code {
  line-height: 35px;
  font-size: 1.1em;
}
.color-thumb {
  position: absolute;
  top: 12px;
  bottom: 12px;
  right: 12px;
  width: 16px;
}
</style>
