<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">

      <div class="row">
        <div class="col-md-6">

          <div class="font-weight-semibold text-slate">
            {{ $t('common.rrule.start_input') }}
          </div>
          <v-date-picker
            :locale="$i18n.locale"
            v-model='currentVal["DTSTART"]'
            :popover="{ placement: 'bottom', visibility: 'click' }"
          >
            <div slot-scope='props' class="">
              <div
                contenteditable="true"
                class="cursor-pointer form-control"
                v-html="formatSelectedDate(props.inputValue)"
              >
              </div>
            </div>
          </v-date-picker>

        </div>
        <div class="col-md-6" v-if="dateStartToDate">

          <div class="font-weight-semibold text-slate">
            {{ $t('common.rrule.recur_input') }}
          </div>
          <select class="form-control select-recurrence" v-model="recurrence">
            <option value="">{{ $t('common.rrule.recurrence.one_time') }}</option>
            <option value="weekly">{{ $t('common.rrule.recurrence.weekly', { weekday: dateStartToDate.format('dddd') }) }}</option>
            <option value="monthly">{{ $t('common.rrule.recurrence.monthly', { day: dateStartToDate.format('Do') }) }}</option>
            <option value="yearly">{{ $t('common.rrule.recurrence.yearly', { date: dateStartToDate.format('Do MMMM') }) }}</option>
          </select>

        </div>
        <div class="col-md-6 mt-2" v-if="dateStartToDate && recurrence && recurrence != ''">

          <div class="font-weight-semibold text-slate">
            {{ $t('common.rrule.end_input') }}
          </div>
          <v-date-picker
            :min-date="endMinDate"
            :locale="$i18n.locale"
            clearable
            v-model='currentVal["UNTIL"]'
            :popover="{ placement: 'bottom', visibility: 'click' }"
          >
            <div slot-scope='props' class="">
              <div
                clearable
                contenteditable="true"
                class="cursor-pointer form-control"
                v-html="formatSelectedDate(props.inputValue)"
              >
              </div>
            </div>
          </v-date-picker>

        </div>
      </div>

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view' && dateStartToDate">

      <span v-if="!value.FREQ">{{ dateStartToDate.format('dddd MMMM Do YYYY') }}</span>
      <span v-else class="">
        <span v-if="value.FREQ == 'WEEKLY'">{{ $t('common.rrule.recurrence.weekly', { weekday: dateStartToDate.format('dddd') }) }}</span>
        <span v-else-if="value.FREQ == 'MONTHLY'">{{ $t('common.rrule.recurrence.monthly', { day: dateStartToDate.format('Do') }) }}</span>
        <span v-else-if="value.FREQ == 'YEARLY'">{{ $t('common.rrule.recurrence.yearly', { date: dateStartToDate.format('Do MMMM') }) }}</span>
        <span v-if="dateUntilDate && value.UNTIL && value.UNTIL != ''"> {{ $t('common.rrule.recurrence.until', { date: dateUntilDate.format('Do MMMM') }) }}</span>
      </span>

    </template>


  </div>
</template>

<script>

import Field from './_Field'
import moment from 'moment-timezone'
import _ from 'lodash'

export default {
  extends: Field,
  components: {
  },
  data () {
    return {
      recurrence: "",
      defaultVal: {},
      currentVal: {},
      currentDate: null,
      rrule: {},
    }
  },
  mounted () {
    if (!this.value || this.value == "") {
      this.currentVal = this.defaultVal
    } else {
      this.currentVal = {
        DTSTART: this.value.DTSTART ? moment(this.value.DTSTART).toDate() : null,
        UNTIL: this.value.UNTIL ? moment(this.value.UNTIL).toDate() : null,
        FREQ: this.value.FREQ ? this.value.FREQ : null,
      }
      this.recurrence = this.currentVal.FREQ.toLowerCase()
      this.rrule = _.cloneDeep(this.value)
    }
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val)
    },
    formatSelectedDate(val, placeholder = this.$t('common.rrule.date_placeholder')) {
      let icon = '<i class="icon-calendar22 text-grey mr-2"></i>'
      let currentYear = new Date().getFullYear()
      if (val == '' || val == null) {
        return icon + '<span class="text-grey">' + placeholder + '</span>'
      } else {
        val = moment(val, "DD/MM/YYYY")
        val = this.$d(val.toDate(), val.format('YYYY') != currentYear ? 'classicText' : 'classicTextWoYear')
      }
      return icon + _.upperFirst(val)
    },
    checkEndDate () {
      if (this.completeRrule && this.completeRrule.UNTIL && moment(this.completeRrule.UNTIL).isBefore(moment(this.endMinDate))) {
        this.currentVal.UNTIL = null
      }
    },
  },
  computed: {
    endMinDate () {
      let initDate = _.cloneDeep(this.dateStartToDate)
      if (this.completeRrule && this.completeRrule.FREQ == "WEEKLY") {
        initDate.add(1, 'weeks')
      }
      if (this.completeRrule && this.completeRrule.FREQ == "MONTHLY") {
        initDate.add(1, 'months')
      }
      if (this.completeRrule && this.completeRrule.FREQ == "YEARLY") {
        initDate.add(1, 'year')
      }
      return initDate.format()
    },
    dateStartToDate () {
      if (!this.currentVal.DTSTART) {
        return
      }
      return moment(this.currentVal.DTSTART)
    },
    dateUntilDate () {
      if (!this.currentVal.UNTIL) {
        return
      }
      return moment(this.currentVal.UNTIL)
    },
    completeRrule () {
      let obj = this.rrule
      obj.DTSTART = this.currentVal.DTSTART ? moment(this.currentVal.DTSTART).format().slice(0,19) : null
      obj.UNTIL = this.currentVal.UNTIL ? moment(this.currentVal.UNTIL).format().slice(0,19) : null
      return obj
    }
  },
  watch: {
    completeRrule: {
      handler(newVal) {
        this.changeVal(newVal)
      },
      deep: true,
    },
    ['currentVal.DTSTART'] (newVal) {
      this.checkEndDate()
    },
    ['currentVal.FREQ'] (newVal) {
      this.checkEndDate()
    },
    recurrence: {
      handler(newVal) {
        if (!newVal || newVal == "" || newVal == "other") {
          this.rrule = {}
        } else if (newVal == "weekly") {
          this.rrule = {
            FREQ: "WEEKLY",
          }
        } else if (newVal == "monthly") {
          this.rrule = {
            FREQ: "MONTHLY",
          }
        } else if (newVal == "yearly") {
          this.rrule = {
            FREQ: "YEARLY",
          }
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ {
  .in-next-month,
  .in-prev-month {
    .vc-opacity-0 {
      color: #a0aec0;
      opacity: 0.5;
    }
  }
}
.select-recurrence {
  height: 2.75em !important;
  line-height: 2.75em !important;
}
</style>
