<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">

      <label class="d-block cursor-pointer">
        <i class="mr-1 icon-checkbox-checked2 text-success" v-if="clonedValue" />
        <i class="mr-1 icon-checkbox-unchecked2 text-grey" v-else />
        <input class="d-none" type="checkbox" v-model="clonedValue" @change="!emitting ? changeVal(clonedValue) : ''">
        {{ $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}`) }}
      </label>

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view' && value != false">

      <span v-if="value" class="font-weight-bold">
        <i class="mr-1 icon-checkbox-checked2 text-success" />
        {{
          $te(`modules.${$route.params.module}.entities.${entityType}.fields.${id}.title`) ? $t(`modules.${$route.params.module}.entities.${entityType}.fields.${id}.title`) : $t(`modules.${$route.params.module}.entities.${entityType}.fields.${id}`)
        }}
      </span>
      <span v-else>
        <!-- <i class="mr-1 icon-checkbox-unchecked2" /> -->
        {{ $t('common.no') }}
      </span>

    </template>
  </div>
</template>

<script>

import Field from './_Field'

export default {
  extends: Field,
  components: {
  },
  data () {
    return {
      emitting: false,
      clonedValue: null,
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.emitting = true
      this.$emit('input', val)
      this.emitting = false
    },
  },
  computed: {
  },
  watch: {
    value: {
      handler (newVal) {
        this.clonedValue = newVal ? newVal : false
      },
      immediate: true,
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
