var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header border-0 bg-white rounded pb-0"},[_c('el-card-title',{attrs:{"title":_vm.dataForm.id ? _vm.$t(("modules." + (this.$route.params.module) + "." + (_vm.$route.params.page) + ".buttons.edit")) : _vm.$t(("modules." + (this.$route.params.module) + "." + (_vm.$route.params.page) + ".buttons.add"))}})],1),_c('div',{staticClass:"card-header"},[_c('form',{ref:"moduleForm"},[_vm._l((_vm.fields),function(fieldConfig,fieldKey,fieldIndex){return ((!fieldConfig.display_modes || fieldConfig.display_modes.edit !== false) && _vm.displayField(fieldKey))?_c('div',{ref:_vm.getFormFieldKey(fieldKey),refInFor:true,staticClass:"form-group"},[(
            fieldConfig.type !== 'info' &&
            fieldConfig.type !== 'checkbox' &&
            fieldConfig.type !== 'heading' &&
            fieldConfig.type !== 'formpart' &&
            fieldConfig.type !== 'separator'
          )?_c('label',{class:{
            'font-weight-bold text-dark': true,
            'label-required': fieldConfig.required
          },attrs:{"for":fieldKey}},[(!fieldConfig.options)?[_vm._v(" "+_vm._s(_vm.$t(("modules." + (_vm.$route.params.module) + ".entities." + (_vm.module.pages[_vm.$route.params.page].render.data) + ".fields." + fieldKey)))+" ")]:_vm._e(),(fieldConfig.options)?[_vm._v(" "+_vm._s(_vm.$t(("modules." + (_vm.$route.params.module) + ".entities." + (_vm.module.pages[_vm.$route.params.page].render.data) + ".fields." + fieldKey + ".title")))+" ")]:_vm._e()],2):_vm._e(),(!fieldConfig.multiple)?_c(("module-form-field-" + (fieldConfig.type)),{tag:"container",attrs:{"index":fieldIndex,"entityType":_vm.entity,"id":fieldKey,"config":fieldConfig,"module":_vm.module,"required":fieldConfig.required ? true : false,"type":fieldConfig.type,"placeholder":_vm.$te(("modules." + (_vm.$route.params.module) + ".entities." + (_vm.module.pages[_vm.$route.params.page].render.data) + ".fields." + fieldKey)) &&
            typeof _vm.$t(("modules." + (_vm.$route.params.module) + ".entities." + (_vm.module.pages[_vm.$route.params.page].render.data) + ".fields." + fieldKey)) == 'string' ?
             _vm.$t(("modules." + (_vm.$route.params.module) + ".entities." + (_vm.module.pages[_vm.$route.params.page].render.data) + ".fields." + fieldKey)) : '',"mode":"edit"},on:{"removeMedia":function($event){return _vm.mediasToRemove.push($event)}},model:{value:(_vm.dataForm[fieldKey]),callback:function ($$v) {_vm.$set(_vm.dataForm, fieldKey, $$v)},expression:"dataForm[fieldKey]"}},[_vm._v(" Invalid form field type : "),_c('strong',[_vm._v(_vm._s(fieldConfig.type))])]):_c('div',_vm._l((((_vm.dataForm[fieldKey] != '' && _vm.dataForm[fieldKey] != []) ? _vm.dataForm[fieldKey].length + 1 : 1)),function(value,valueIndex){return _c('div',{staticClass:"mb-1"},[_c(("module-form-field-" + (fieldConfig.type)),{tag:"container",attrs:{"index":fieldIndex,"entityType":_vm.entity,"id":fieldKey,"config":fieldConfig,"module":_vm.module,"required":fieldConfig.required ? true : false,"type":fieldConfig.type,"placeholder":_vm.$te(("modules." + (_vm.$route.params.module) + ".entities." + (_vm.module.pages[_vm.$route.params.page].render.data) + ".fields." + fieldKey)) && typeof _vm.$te(("modules." + (_vm.$route.params.module) + ".entities." + (_vm.module.pages[_vm.$route.params.page].render.data) + ".fields." + fieldKey)) == 'string' ?
                            _vm.$t(("modules." + (_vm.$route.params.module) + ".entities." + (_vm.module.pages[_vm.$route.params.page].render.data) + ".fields." + fieldKey)) : '',"mode":"edit"},on:{"removeMedia":function($event){return _vm.mediasToRemove.push($event)}},model:{value:(_vm.dataForm[fieldKey][valueIndex]),callback:function ($$v) {_vm.$set(_vm.dataForm[fieldKey], valueIndex, $$v)},expression:"dataForm[fieldKey][valueIndex]"}},[_vm._v(" Invalid form field type : "),_c('strong',[_vm._v(_vm._s(fieldConfig.type))])])],1)}),0),_vm._v(" "),(_vm.errors && _vm.errors[fieldKey] && _vm.errors[fieldKey].length > 0)?_c('div',[_c('small',{staticClass:"text-danger font-weight-bold"},[_c('i',{staticClass:"icon-warning22 small"}),_vm._v(" "+_vm._s(_vm.errors[fieldKey][0]))])]):_vm._e()],1):_vm._e()}),_vm._v(" "),_c('div',[_c('button',{staticClass:"px-3 btn btn-success text-white",attrs:{"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[(!_vm.loading)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")]):_c('loader')],1)])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }