<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">

      <div class="form-control d-flex align-items-center justify-content-between" :for="id" v-if="value && value.file_name && value.human_filesize">
        <div>
          <span>{{ value.file_name }}</span>
          <span class="ml-1 badge badge-light">{{ value.human_filesize }}</span>
        </div>
        <i class="icon-cross2 text-danger cursor-pointer" @click="file = null; changeVal(null); $emit('removeMedia', value.id)"></i>
      </div>
      <input
        v-else
        @change="onFileChange"
        :required="required ? true : false"
        type="file"
        :field="id"
        class="form-control"
        :id="id"
        :accept="config && config.mimes ? config.mimes.join(',') : null"
        :placeholder="placeholder"
      >

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">

      <i :class="`${fileIcon} text-slate mr-1`"></i>
      <a :href="value['urls']['full']" target="_new">{{ value['file_name'] }}</a> <span class="badge badge-light">{{ value.human_filesize }}</span>

    </template>

  </div>
</template>

<script>

import _ from 'lodash'

import Field from './_Field'

export default {
  extends: Field,
  components: {
  },
  data () {
    return {
      file: null,
    }
  },
  mounted () {
  },
  methods: {
    onFileChange(e) {
      let field = e.srcElement.attributes.field.value

      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
          return;

      this.changeVal(files[0])
    },
    changeVal(val) {
      this.$emit('input', val)
    },
  },
  computed: {
    fileIcon () {
      let icon = 'icon-file-download'
      if (this.value && this.value.mime_type) {
        switch (this.value.mime_type) {
          case 'audio/midi':
          case 'audio/ogg':
          case 'audio/x-wav':
          case 'audio/aac':
          case 'audio/mpeg':
          case 'audio/x-ms-wma':
            icon = 'icon-file-music'
           break;
          case 'application/pdf':
            icon = 'icon-file-pdf'
           break;
          case 'application/msword':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            icon = 'icon-file-word'
           break;
          case 'application/vnd.ms-excel':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            icon = 'icon-file-excel'
           break;
          case 'application/zip':
            icon = 'icon-file-zip'
           break;
          case 'text/css':
          case 'text/html':
          case 'text/javascript':
            icon = 'icon-file-css'
           break;
          case 'image/png':
          case 'image/jpeg':
          case 'image/gif':
          case 'image/svg+xml':
            icon = 'icon-file-picture'
            break;
          case 'video/mpeg':
          case 'video/mp4':
          case 'video/quicktime':
          case 'video/x-ms-wmv':
          case 'video/x-msvideo':
            icon = 'icon-file-video'
            break;
        }
      }
      return icon
    }
  },
  watch: {
    value (newVal) {
      this.file = _.cloneDeep(newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
