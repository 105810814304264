export default {
  props: {
    mode: {
      type: String,
      default: 'edit',
      validator: function (value) {
        return ['edit', 'view'].indexOf(value) !== -1
      }
    },
    type: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      // Type can be any, depending on the field type : Boolean, Object, Array, String, ...
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    entity: {
      type: Object,
      required: false,
    },
     entityType: {
      type: String,
      required: true,
    },
    config: {
      type: [Array, Object],
      required: true,
    },
    module: {
      type: [Array, Object],
      required: true,
    },
  },
};
