export default {
  props: ['data', 'fields', 'module', 'entity', 'page', 'config'],
  // props: {
  //   mode: {
  //     type: String,
  //     default: 'edit',
  //     validator: function (value) {
  //       return ['edit', 'view'].indexOf(value) !== -1
  //     }
  //   },
  // },
};
