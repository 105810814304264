<template>
  <div>
    <el-table
      :total="Object.keys(data).length"
      :columns="columnsFormatted"
      :data="dataFormatted"
    >

        <div slot="actions" slot-scope="{row}">
        </div>

    </el-table>
  </div>
</template>

<script>

import Index from './_Index'

export default {
  extends: Index,
  name: 'ModuleIndexTable',
  computed: {
    users () {
      return this.$store.getters['world/getUsers']
    },
    usersByKey () {
      return _.keyBy(this.users, 'id')
    },
    columnsFormatted () {
      if (!this.options.columns) {
        return {}
      }
      let columns = {}
      this.options.columns.forEach( element => {
        columns[element] = this.$te(`modules.${this.$route.params.module}.entities.${this.entity}.fields.${element}.title`) ? this.$t(`modules.${this.$route.params.module}.entities.${this.entity}.fields.${element}.title`) : this.$t(`modules.${this.$route.params.module}.entities.${this.entity}.fields.${element}`)
      })
      return columns
    },
    dataFormatted () {
      if (!this.dataFiltered) {
        return {}
      }
      let data = []
      this.dataFiltered.forEach( (row, rowIndex) => {
        // Format fields
        _.each(row, (fieldData, fieldKey) => {
          if (this.fields[fieldKey] && this.fields[fieldKey].type == 'user') {
            this.dataFiltered[rowIndex][fieldKey] = this.usersByKey[fieldData] ? this.usersByKey[fieldData].full_name : ''
          }
        })

        // Add link
        row.link = this.$route.path + '#' + row.id
        data.push(row)
      })


      return data
    }
  },
  methods: {
  }
}
</script>
