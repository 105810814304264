<template>

  <hr class="border-grey" />

</template>

<script>

import Field from './_Field'

export default {
  extends: Field,
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>
