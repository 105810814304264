<template>
  <div>

    <kanban-board :stages="fields[options.stage_field]['options']" :blocks="dataFormatted" class="kanban mb-2" @update-block="updateTaskStage">
      <div class="" v-for="stage in fields[options.stage_field]['options']" :slot="stage" :key="stage">
        <div class="card shadow-0">
          <div class="card-body p-2">
            <div class="font-weight-bold m-0 d-flex">
              <div class="">{{ $t(`modules.${$route.params.module}.entities.${render.data}.fields.${options.stage_field}.options.${stage}`) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class=""
        v-for="block in dataFormatted"
        :key="block.id"
        :slot="block.id"
      >
        <a class="card cursor-pointer mb-2 px-3 py-2 text-secondary" :href="'#' + block.id">
          <div>
            <span class="w-auto mb-1 badge badge-info text-left" v-if="options.category_field && block.type">
              {{ $t(`modules.${$route.params.module}.entities.${render.data}.fields.${options.category_field}.options.${block.type}`) }}
            </span>
          </div>
          <div class="font-weight-semibold">{{ block[config.name_field] }}</div>
          <div class="mt-1 small" v-if="options.actions_field && block[options.actions_field] && block[options.actions_field].length > 0">
            <div
              v-for="action in block[options.actions_field]"
            >
            —
              <span
                :class="{
                  'text-striked': action.done,
                }"
              >
                {{ action.name }}
              </span>
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-center" v-if="
            (options.date_field && block[options.date_field]) || (options.user_field && block[options.user_field])
          ">
            <div class="small text-grey">
              <span v-if="options.date_field && block[options.date_field]">
                {{ $d(new Date(block[options.date_field]), 'shortText') }}
              </span>
            </div>
            <div>
              <div v-if="options.user_field && block[options.user_field]">
                <el-avatar
                  size="xsmall"
                  :src="users[block[options.user_field]].avatar.thumb"
                  class="ml-1"
                  v-tooltip="{
                    content: users[block[options.user_field]].full_name,
                    placement: 'top',
                  }"
                />
              </div>
            </div>
          </div>
        </a>
      </div>
    </kanban-board>

  </div>
</template>

<script>

import Index from './_Index'
import _ from 'lodash'

import {
  MODULE_UPDATE,
} from '../../../store/mutations-types'

export default {
  extends: Index,
  name: 'ModuleIndexKanban',
  computed: {
    dataFormatted () {
      return _.cloneDeep(this.dataFiltered).map(elem => {
        elem.status = elem[this.options.stage_field]
        return elem
      })
    },
    users () {
      let users = this.$store.getters['world/getUsers'] ? this.$store.getters['world/getUsers'] : [];
      return _.keyBy(users, 'id')
    },
  },
  methods: {
    updateTaskStage (id, status) {

      let blockId = this.data.findIndex(b => b.id === Number(id))
      this.$set(this.data[blockId], this.options.stage_field, status)

      this.$store.dispatch('module/' + MODULE_UPDATE, {
        worldId: this.$route.params.world,
        moduleId: this.$route.params.module,
        entityType: this.module.pages[this.$route.params.page].render.data,
        data: this.data[blockId],
      }).then(data => {
      })

    },
  }
}
</script>
<style lang="scss" scoped>

/deep/ {
  .kanban {
    overflow-x: scroll;
  }
  .drag-list {
    min-width: 600px;
  }
  .drag-item {
    cursor: move;
    transition: all .3s cubic-bezier(.23,1,.32,1);
    word-break: break-word;
  }
  .drag-item.is-moving {
    transition: all .3s cubic-bezier(.23,1,.32,1);
    transform: scale(1.02);
    opacity: .8;
  }
  .drag-options {
    position: absolute;
    top: 44px;
    left: 0;
    // width: 100%;
    height: 100%;
    padding: 10px;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
    transition: all .3s cubic-bezier(.23,1,.32,1)
  }
  .drag-options.active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  .drag-options-label {
    display: block;
    margin: 0 0 5px;
  }
  .drag-options-label input {
    opacity: .6;
  }
  .drag-options-label span {
    display: inline-block;
    font-size: .9rem;
    font-weight: 400;
    margin-left: 5px;
  }
}

.kanban {
  /deep/ .badge {
    white-space: normal !important;
  }
  ul, /deep/ ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  /deep/ > .drag-list {
    display: flex;
    margin: 0 -5px;
    > .drag-column {
      padding: 0px 5px;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .drag-inner-list {
        min-height: 50%;
      }
    }
  }
}
.text-striked {
  text-decoration: line-through rgba(black, .5);
  opacity: 0.5;
}
</style>

